/* Success.css */
.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-message {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.success-message h2 {
  color: #27ae60;
  margin-bottom: 10px;
}

.success-message p {
  margin: 5px 0;
  font-size: 16px;
}

.success-message .label {
  font-weight: bold;
  color: #555;
}