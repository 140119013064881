/* Training.css */
.training-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 100px;
  width: 100%;
}

.banner-image {
  width: 100%;
  /* Ensure the image spans the full width of the container */
  height: 340px;
  /* Set the height of the banner image */
  object-fit: cover;
  /* Cover mode ensures the image covers the entire container */
  object-position: center;
  /* Center the image within the container */
}

.intro-text {
  font-size: large;
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 0;
}